@mixin remove-highlight(){
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin remove-tap-highlight(){
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}


@mixin shadow(){
  box-shadow: 0px 2px 6px 0px rgba(100,100,110,.2);
}

@mixin iconSort(){
  .icon-sort{
    width: 6px;
    >div{
      cursor: pointer;
    }
    div:nth-child(1){
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent $md-grey transparent;
      margin-bottom: 2px;
      &.active{
        border-color: transparent transparent $secondary-color transparent;
      }
    }
    div:nth-child(2){
      width: 0;
      height: 0;
      border-style: solid;
      border-width:  5px 5px 0 5px;
      border-color: $md-grey transparent transparent transparent;
      margin-top: 2px;
      &.active{
        border-color: $secondary-color transparent transparent transparent;
      }
    }
  }
}

@mixin block(){
  position: relative;
  background-color: $white;
  padding: $margin-sm;
  margin-bottom: $margin-sm;
  border-radius: $radius;

  .flash {
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    transform: translate(-50%, -35%);
    @media screen and (min-width: #{$sm-screen}) {
      width: 50px;
    }
  }


  @include shadow();
  @media screen and (min-width: #{$sm-screen}) {
    padding: 20px $margin-md;
  }

  >img{
    width: calc(100% - 45px);
    margin-bottom: $margin-sm;
    @media screen and (min-width: #{$sm-screen}) {
      width: 100%;
    }
  }

  h3+p{
    margin-top: $margin-sm;
  }

  h3{
    position: relative;
    margin-top: 0;
    padding-bottom: $margin-xs;
    font-weight: normal;
    span{
      font-size: 16px;
      font-family: "MontSerrat-SemiBold";
    }

    .hightlight{
      font-family: "Montserrat-SemiBold";
      font-size: 16px;
      color: $primary-color;
    }

    &:not(.title-mobile):after{
      content : "";
      position: absolute;
      display: block;
      bottom: 0;
      height: 1px;
      width: 50%;
      max-width: 180px;
      background-color: $primary-color;
      transform-origin: center;
    }

    &.title-mobile{
      display: flex;
      justify-content: space-between;
      vertical-align: middle;
      margin-bottom: 0;
      padding-bottom: 0;
      padding-right: 40px;
      line-height: 20px;

      &.open{
        position: static !important;
      }

      &:after{
        width: 30%;
      }

    }

  }

  button{
    margin: $margin-sm  0;
    background-color: $secondary-color;
    color: $white;
    font-family: "MontSerrat-SemiBold";
    min-width: 180px
  }

  .semibold{
    font-family: "MontSerrat-SemiBold";
  }

  .title-mobile.open .show{
    top:$margin-sm;
    right: $margin-sm;
    transform: translateY(0%);
  }

  .show{
    position: absolute;
    display: inline-block;
    width: 30px;
    min-width: 30px;
    height: 30px;
    top:50%;
    right: 0;
    border-radius: 15px;
    border: solid 1px $secondary-color;
    transform: translateY(-50%);

    &:after,
    &:before{
      content : "";
      position: absolute;
      top:50%;
      left:50%;
      display: block;
      height: 1px;
      width: 18px;
      background-color: $secondary-color;
      transform-origin: center;
      transition: all .4s ease-in-out;
    }

    &:after{
      transform: translate(-50%,-50%) rotate(90deg);
    }

    &:before{
      transform: translate(-50%,-50%);
    }

    &.active{
      &:after{
        transform: translate(-50%,-50%) rotate(90deg) scale(1,0);
      }
    }

  }
}

@mixin loader(){
  .loader{
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: lighten($primary-color,30%);
      border-radius: 5px;
      animation-iteration-count: infinite;
      animation-duration: 1s;
      animation-name: rotate;
      margin-left: 12px;
      &.show{
        display: inline-block;
      }
  }

  @keyframes rotate {
      0% {
        transform: rotateX(0) rotateY(0);
        }
      50% {
        transform: rotateX(-180deg) rotateY(0);
      }
      100% {
        transform: rotateX(-180deg) rotateY(-180deg);
      }
  }
}

@mixin checkbox {
  .checkbox{
    display: flex;
    align-items: flex-start;
    label{
      padding-left: $margin-sm;
    }
  }
}

@mixin sectionTitle(){
  .section-title{
    font-family: "Montserrat-SemiBold";
    padding: $margin-sm $margin-md;
    .required{
      color : $primary-color;
    }
  }
}

/// ADD CONTACT
@mixin addContact(){
  .addContact{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: $margin-sm;
    cursor: pointer;
    p{
      margin: 0;
    }
    &:hover{
      p{
        color:$primary-color;
        text-decoration: underline;
      }
      .add {
        background-color: darken($primary-color,3%);
      }
    }

    .add{
      background-color: $primary-color;
      color: $white;
      height: 30px;
      width: 30px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: $margin-xs;
      font-size: $h2;
      cursor: pointer;
      transition: all .3s ease-in-out;

    }

  }
}

// INPUT NUMBER
@mixin inputNumber(){
  .input-number{

    &:not(.disabled){
      min-width: 160px;
      .unit{
        width: 160px;
      }
    }

    &.disabled {
      min-width: 100px;

      button{
        background-color: $md-grey;
        border-color: $md-grey;
      }
      input{
        pointer-events: none;
      }
      .unit{
        width: 70px;
        margin: 5px 5px 0 5px;
      }
    }

    button{
      background-color: $secondary-color;
      border-color: $secondary-color;
      border-radius: 12px;
      height: 40px;
      width:40px;
      min-width: 40px;
      padding: 0;
      font-size: 30px;
      line-height: 30px;
      vertical-align: top;
    }
    input{
      margin: 0 5px;
      max-width: 70px;
      text-align: center;
      -moz-appearance: textfield;
      will-change: transform;  
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

    }
    button,input{
      margin-bottom: 0;
    }

    .unit{
      margin-top: 5px;
      text-align: center;
    }
  }
}

/// LINE USER
@mixin lineUser(){
  .line-user{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    padding: $margin-sm $margin-xs;
    margin-bottom: $margin-sm;
    border-radius: 0;
    background: $white;
    box-shadow: $shadow;

    @media screen and (min-width: #{$sm-screen}) {
      border-radius: $radius;
    }

    @media screen and (min-width: #{$md-screen}) {
      align-items: center;
      padding: $margin-sm $margin-sm;
    }

    &.loading{
      opacity: .5;
      pointer-events: none;
    }

    @include loader();
    .loader{
      position: absolute;
      top:calc(50% - 5px);
      right: 10px;
    }

    p{
      margin: 0;
    }

    .delete{
      margin-left: $margin-xs;

      img{
        display: block;
      }

      &:not(:hover) img:nth-child(2){
        display: none;
      }

      &:hover img:first-child{
        display: none;
      }

    }


    .container{
      min-width: 25%;
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-direction: column;
      border-right: solid 1px #dedede;

      @media screen and (min-width: #{$md-screen}) {
        min-width:50%;
        flex-direction: row;
      }

      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        flex:1;
        width: 100%;
        padding: $margin-xs;

        @media screen and (min-width: #{$md-screen}) {
          width: 33.3%;
          justify-content: flex-start;
          padding: $margin-sm;
        }

        p{
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          &:hover{
            white-space:normal;
          }
        }

        &:first-child{
          padding-right: $margin-sm;
        }
        &:not(:first-child){
          padding: $margin-xs $margin-sm;
          border-left: none;
          @media screen and (min-width: #{$md-screen}) {
            border-left: solid 1px $md-grey;
          }
        }

      }

    }

    .edit{
      display: flex;
      flex-wrap: wrap;
      border: none !important;
      cursor: pointer;
      padding: 10px 15px;

      width: 100%;
      justify-content: flex-end;

      @media screen and (min-width: #{$md-screen}) {
        width: auto;
        justify-content: inherit;
      }

      p:last-child{
        margin-left: $margin-sm;
      }
      p:hover{
        color : $primary-color;
        text-decoration: underline;
      }
    }

    .presence-days{
      border: none !important;
      width: 100%;
      @media screen and (min-width: #{$md-screen}) {
        width: auto;
      }
    }

    .presence-days ul{
      height: 100%;
      padding: 0;
      margin: 0;
      text-align: center;
      @media screen and (min-width: #{$md-screen}) {
        text-align: left;
      }
      li{
        list-style-type: none;
        display: block;
        @media screen and (min-width: #{$md-screen}) {
          display: inline-block;
        }
        padding: 10px 15px;
        p span,
        p{
          font-family: "MontSerrat-SemiBold";
          color: $dark-blue
        }
      }

      li.disabled p{
        color : $text-disabled-color;
        font-family: "MontSerrat-Regular";
      }

      li{
        padding: 0 $margin-sm;
      }

      li img{
        height:18px;
        position: relative;
        top:1px;
        margin-left: $margin-xs;
      }

    }

  }
}

@mixin close(){
  .close{
    position: relative;
    width: 25px;
    height: 25px;
    cursor: pointer;

    &:after,&:before{
      content: "";
      position: absolute;
      top:50%;
      left:50%;
      display: block;
      width: 30px;
      height: 4px;
      border-radius: 2px;
      background-color: $white;
      transform-origin: center;

    }

    &:after{
      transform: translate(-50%,-50%) rotate(-45deg);
    }
    &:before{
      transform: translate(-50%,-50%) rotate(45deg);
    }
  }
}

@mixin burger(){
  .burger{
    position: relative;
    width: 30px;
    height: 22px;
    cursor: pointer;
    @include remove-tap-highlight;

    >div{
      position: absolute;
      width: 30px;
      height: 4px;
      border-radius: 2px;
      background-color: $secondary-color;
      transform-origin: center;
      transform: translateY(-50%);

      &:nth-child(1){
        top: 50%;
      }

      &:last-child{
        top: 100%;
      }

    }
  }
}

@mixin pastille{
  .pastille{
    position: absolute;
    z-index: 0;
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;
    border-radius: 8px;
    overflow: hidden;
    &.maison{
      background: $primary-color;
    }
    &.multimedia{
      background: $secondary-color;
    }

    &.violet{
      background: #9430EB;
    }

    &.expert,
    &.green{
      background: $green;
    }

    &.multimedia-maison{
      &:after,
      &:before{
        position: absolute;
        content: '';
        display: block;
        top:50%;
        width: 80px;
        height: 80px;
      }

      &:after{
        background: $secondary-color;
        left:50%;
        transform-origin: center left;
        transform: translateY(-50%) rotate(45deg);
      }

      &:before{
        background: $primary-color;
        right:50%;
        transform-origin: center right;
        transform: translateY(-50%) rotate(45deg);
      }

    }

  }
}

@mixin btnClose(){
  .button-close{
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background-color: $primary-color;
    box-shadow: 0 4px 12px 0 rgba(208,2,37,.3);
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    &:hover{
      background-color: darken($primary-color, 5%);
    }

    .dash{
      position: absolute;
      height: 3px;
      width: 10px;
      background: white;
      transform-origin: right;
      transition: all .4s ease-in-out;
      &:nth-child(1){
        transform: translate(-50%) rotate(45deg);
      }
      &:nth-child(2){
        transform: translate(-50%) rotate(135deg);
      }
      &:nth-child(3){
        transform: translate(-50%) rotate(225deg);
      }
      &:nth-child(4){
        transform: translate(-50%) rotate(315deg);
      }
    }

    &.fadeOut{
      .dash{ opacity: 0}
      animation-name: fadeOutCircle;
      animation-delay: .3s;
      animation-duration: .5s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;

      .dash:nth-child(1){
        transform: translate(-400%, -1200%) rotate(45deg);
      }
      .dash:nth-child(2){
        transform: translate(400%,-1200%) rotate(135deg);
      }
      .dash:nth-child(3){
        transform: translate(300%,1200%) rotate(225deg);
      }
      .dash:nth-child(4){
        transform: translate(-400%,1200%) rotate(315deg);
      }
    }

  }
}

@mixin listProducts(){
  .list-products{

    padding-bottom: $margin-md;

    @media screen and (max-width: #{$sm-screen}){
      padding: 0 $margin-sm;
    }

    .sortBy{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $margin-sm ;
      background-color: $light-grey;
      @include iconSort;

      &.fixed{
        top:0;
        position: fixed;
        z-index: 100;
        max-width: 1224px;
        width: 100%;
        @media screen and (min-width: #{$sm-screen}) {
          width: 90%;
        }
        &:after,&:before{
          content : "";
          position: absolute;
          display: block;
          height: 100%;
          width: 200px;
          background-color: $light-grey;
        }
        &:after{
          right: 0;
          transform: translateX(100%);
        }
        &:before{
          left: 0;
          transform: translateX(-100%);
        }
      }

      >div{
        width: 100%;
        padding: 0 5px;
        &:last-child{
          min-width: 160px;
        }

        &.product-packing{
          max-width: 100px;
        }

        &.product-code{
          max-width: 125px;
        }

        &.product-price{
          max-width: 170px;
        }

      }
      p{
        margin: 0;
        color : $text-disabled-color;
        font-size: $small;
        cursor: pointer;
        @include remove-highlight();
        @include remove-tap-highlight();
      }

      >div{
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .product-qty{
        min-width: 160px;
      }

      .icon-sort{
        margin-left: 5px;
        transition: all .3s ease-in-out;
        // transform-origin: center;
        cursor: pointer;
        &:hover{
          margin-left: 8px;
          transform: scale(1.5);
        }
      }

    }


    ul{
      padding: 0;
      margin: 0;
      width: 100%;
      li{
        display: block;
      }

      li>div,
      li a{
        position: relative;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        background-color: $white;
        padding: $margin-sm;
        border-radius: $radius;
        margin-bottom: $margin-sm;
        text-decoration: none;
        color :$text-color;

        @include shadow();
        @include remove-tap-highlight();

        >div{
          padding: 0 5px;
          word-break: break-word;
        }

        .discount{
          position: absolute;
          top:0;
          left:0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 38px;
          height: 38px;
          background-color: $primary-color;
          border-radius: 19px;
          color: $white;
          transform: translate(-30%,-30%);
          word-break: keep-all;
          p{
            position: relative;
            left:2px;
          }
          .unit{
            position: relative;
            font-size: 10px;
            top:-3px;
          }

          img{
            width: 18px;
            &.punch {
              width: 30px;
            }
          }

        }

        .flash{
          position: absolute;
          top:0;
          left:0;
          width: 30px;
          transform: translate(-40%,-40%);
        }

        @media screen and (min-width: #{$md-screen}){
          flex-wrap: nowrap;
          justify-content: space-between;
        }

        .product-name .product-ref{
          font-family: "Montserrat-Regular";
          color : $md-grey;
        }

        .product-qty .product-packing p,
        .product-qty .product-segment p{
          font-family: "Montserrat-Regular";
        }



        @media screen and (min-width: #{$md-screen}){
          .product-name .product-ref,
          .product-qty .product-packing,
          .product-qty .product-segment{
            display: none;
          }

          // .product-qty{
          //   display:flex;
          //   justify-content: flex-end;
          // }

          .product-packing{
            max-width: 100px;
          }


          .product-code{
            max-width: 125px;
          }

          .product-price{
            max-width: 170px;
          }



        }


        p{
          font-family: "Montserrat-Medium";
          margin: 0;
        }

        .product-price p{
          font-family: "Montserrat-SemiBold";
          color: $secondary-color;
          font-size: $h2;
          @media screen and (min-width: #{$sm-screen}){
            font-size: $medium;
          }
          @media screen and (min-width: #{$md-screen}){
            font-size: $regular;
          }
        }

        >div{
          width: 100%;
        }


      }
    }
  }
}
