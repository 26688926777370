@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';
@import '../../../scss/partials/tiles.scss';

@include Tiles();
.tiles{
  max-width: 500px;
  margin : 0 auto;
  ul{
    padding: 0;
    margin-bottom: $margin-sm;
  }
  .tile{
    width: calc(100%/2);
    padding-top: calc(100%/2);
    &:nth-child(1) div,
    &:nth-child(3) div{
      padding-left: 0;
    }

    &:nth-child(2) div,
    &:nth-child(4) div{
      padding-right: 0;
    }

    a{
      background-color: $secondary-color;
    }
  }
}