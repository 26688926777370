@mixin Tiles {
  .tiles {
    > ul {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 5px;
      max-width: 650px;
      margin: 0 auto;
    }
    .tile {
      position: relative;
      display: inline-block;
      width: calc(100% / 3);
      padding-top: calc(100% / 3);
      &.inactive {
        pointer-events: none;
        span,
        img {
          opacity: 0.4;
        }
      }
      > div {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 3px !important;
        box-sizing: border-box;
      }
      a {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-align: center;
        color: white;
        font-size: $small;
        background-color: $dark-blue;
        border-radius: $radius;
        background-position: center;
        background-size: cover;
        @include remove-tap-highlight;

        .top-right {
          position: absolute;
          top: $margin-sm;
          right: $margin-sm;
          font-family: "Montserrat-SemiBold";
          font-size: $medium;
        }
      }

      img {
        margin-bottom: 5px;
        max-width: 25px;
      }

      @media screen and (min-width: 500px) {
        > div {
          padding: $margin-xs;
        }
        a {
          font-size: $regular;
        }
        img {
          margin-bottom: $margin-sm;
          max-width: 60px;
        }
      }
    }
  }
}
