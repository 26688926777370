.product{
  height: 100% !important;
  overflow-y: auto;
  // for app
  // overflow-y: scroll;
  @include inputNumber();

  button,a{
    @include remove-tap-highlight();
  }

  a button{
    margin-top: $margin-sm 0;
    @media screen and (min-width: #{$md-screen}){
      margin-top: 0;
    }
  }

  .title-page{
    display: flex !important;
    padding-top: 0;
    h2{
      margin : 0 0 $margin-xs 0;
    }
    @media screen and (min-width: #{$md-screen}){
      padding-top: $margin-sm;
      h2{
        margin: $margin-sm 0;
      }
    }
  }

  .content{
    padding: $margin-sm;
    @media screen and (min-width: #{$sm-screen}){
      padding: 0;
    }
  }

  .title-page{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    p{
      margin: 0;
      padding-left: $margin-sm;
    }

    h2{
      border-right: solid 1px $md-grey;
      padding-right: $margin-sm;
    }

  }

  .container-product{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    >div:last-child{
      display: none;
    }
    @media screen and (min-width: 1150px){
      >div:last-child{
        display: flex;
        padding-top: calc(100% - 730px);
        flex:1;
        @include shadow();
        border-radius: $radius;
        img{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          max-width: 90%;
          max-height: 90%;
        }
      }
    }

  }

  .detail-product{
    position: relative;
    border-radius: $radius;
    background-color: $white;
    @include shadow();
    padding: $margin-sm $margin-md;
    width: 100%;
    max-width: 700px;
    margin-right: 0;

    @media screen and (min-width: #{$sm-screen}){
      margin-right: $margin-md;
    }


    .container-image{
      position: relative;
      z-index: 0;
      display: block;
      img{
        display: block;
        max-width: 300px;
        max-height: 300px;
        object-fit: contain;
        margin: 0 auto $margin-md auto;
      }
      @media screen and (min-width: 1150px){
        display: none;
      }
    }

    .gift{
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary-color;
      width: 56px !important;
      height: 56px;
      border-radius: 28px;
      img{
        width: 30px;
        &.punch {
          width: 40px;
        }
      }
    }

    >.flash,
    >.gift{
      position: absolute;
      z-index: 1;
      top:0;
      left:100%;
      width: 60px;
      transform: translate(-90%,-20%);
      @media screen and (min-width: #{$sm-screen}){
        transform: translate(-50%,-20%);
        left:0;
      }
    }

    .container-btn{
      margin-top: $margin-sm;
    }

  }

  .detail-product section{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;

    &:not(:last-child){
      border-bottom: solid 1px $md-grey;
    }

    .product-qty{
      .goto-order{
        border-color: $primary-color !important;
        color : $primary-color !important;
        margin: 0;
      }
      p{
        width: 160px;
        font-family: "Montserrat-SemiBold";
        text-align: center;
        margin-top: 5px;
      }
    }

    &.first-section{
      >div{
        padding-top: $margin-sm;

        &.product-qty-mobile{
          display: block;
          width: 100% !important;
          border-bottom: none;
          padding-bottom: 0;
          @media screen and (min-width: #{$sm-screen}){
            display: none !important;
          }
          p{
            width: 160px;
            font-family: "Montserrat-SemiBold";
            text-align: center;
            margin-top: 5px;
          }
        }

        @media screen and (max-width: #{$sm-screen}){
          border-bottom: none !important;
          &:not(.product-qty-mobile){
            width: 50% !important;
          }
          &.product-qty{
            display: none;
          }
        }
      }
    }

    .cell-packing{
      display: flex;
      align-items: center;
      label{
        margin-bottom: 0;
      }
    }


    >div{
      width: 100%;
      padding: $margin-md 0;

      &:not(:last-child){
        border-bottom: solid 1px $md-grey;
      }

      @media screen and (min-width: #{$sm-screen}){
        width: 33.3%;
        border-bottom: none !important;
      }

      label{
        font-family: "Montserrat-Medium";
      }

      p{
        margin-bottom: 0;
        word-break: break-word;
      }

      .promo,
      .product-price{
        font-family: "Montserrat-SemiBold";
        font-size: $h2;
      }

      .promo{
        color: $primary-color;
      }

      .product-price{
        color: $secondary-color;
      }

    }

  }

}
