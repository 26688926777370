@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';
@import '../../../scss/partials/tiles.scss';

.slider-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  height: 90px !important;
  width: 100%;
  border-radius: $radius;
  @include shadow();

  &.quizz a,
  &.trophy a{
    display: flex;
    align-items: center;
    padding: $margin-sm;
    img{
      height: 60px;
      margin-right: $margin-sm;
    }
    p{
      text-align: left;
      margin: 0;
    }
  }

  >a{
    width: 100%;
    height: 90px;
    margin-bottom: 0;
    text-decoration: none;
  }

  >p{
    width: 100%;
    margin: 0;
    padding: $margin-sm;
    text-align: center;
  }

  .item-offer{
    display: flex;
    width: 100%;
    height: 90px;
    justify-content: space-between;
    align-items: center;
    padding: $margin-xs $margin-sm;

    .flash{
      position: absolute;
      z-index: 1000;
      top:0;
      left:0;
      transform: translate(-50%,-30%);
      width: 40px;
      height: 40px;

    }

    p{
      margin: 0;
    }

    >div:first-child{
      p:not(.sub){
        font-family: "Montserrat-SemiBold";
        color: $text-color;
      }
      .sub{
        color : $text-disabled-color;
      }
    }

    .price p{
      font-family: "Montserrat-SemiBold";
      font-size: $h2;
    }
  }

}


.salon{
  overflow-y: auto;
  height: calc(100% - 70px) !important;

  @media screen and (min-width: #{$md-screen}) {
    height: calc(100% - #{$header-height}) !important;
  }

  h1,h2,p,li{
    color : $secondary-color;
  }

  h1{
    font-size: $h1;
    margin-top: 0;
  }
  ul{
    padding: 0;
    list-style: none;
    li.dot{
      position: relative;
      padding-left: 10px;
      margin: 0 0 $margin-xs 0;
      &:before {
        content: "";
        position: relative;
        top: -1px;
        left: -9px;
        display: inline-block;
        height: 6px;
        width: 6px;
        display: inline-block;
        border-radius: 3px;
        background-color: $primary-color;
      }
      font-size: $regular;
    }
  }

  h2{
    font-family: "MontSerrat-Regular";
    margin-bottom: 0;
    margin-top: 0;
    font-size: $medium;
  }

  @media screen and (max-width: #{$md-screen}) {
    .mobile-centered{
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }


  @media screen and (min-width: #{$md-screen}) {
    h2{
      font-size: $h2;
      margin-top: $margin-sm;
    }
    h1{
      font-size: $xl;
    }
  }

  b{
    font-family : "MontSerrat-SemiBold"
  }

  p{
    margin-bottom: $margin-sm;
    &.bold{
      font-family : "MontSerrat-SemiBold"
    }
  }

  .content{
    position: relative;
    height: auto;
    box-sizing: border-box;
    padding: 0 $margin-sm;

    @media screen and (min-width: #{$md-screen}) {
      padding: 0;
    }

    .container-col{
      position: relative;
      transform: translateY(0);
      width: 100%;

      display: flex;
      flex-wrap: wrap;
      flex-direction: column-reverse;

      @media screen and (min-width: #{$sm-screen}) {
        flex-direction: column;
      }

      @media screen and (min-width: #{$md-screen}) {
        flex-direction: row;
      }
    }

    .col{

      width: 100%;
      padding: 0 $margin-sm;

      >div{
        width: 100%;
      }

      @media screen and (min-width: #{$md-screen}) {
        &:first-child{
          width:50%;
        }
        &:last-child{
          width:50%;
        }
      }


    }

    .cover {
      border-radius: $radius;
      overflow: hidden;
      margin: $margin-sm 0;
      font-size: 0;

      img{
        object-fit: contain;
        width: 100%;
      }
    }

  }

  .support-contact{
    position: relative;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding: $margin-sm 0;
    @media screen and (min-width: #{$md-screen}) {
      padding: $margin-sm $margin-md;

      justify-content: center;
      // position: absolute;
      // bottom: 0px;
    }

  }

  .plan{
    position: relative;
    overflow: hidden;
    border-radius: $radius;
    margin: $margin-sm 0;
    font-size: 0;

    a{
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(22,27,70,.45);
      font-family : "Montserrat-SemiBold";
      font-size: $medium;
      color: $white;
      text-align: right;
      padding: $margin-sm;
    }

    @include shadow();
    img{
      width: 100%;
    }
  }

  @include Tiles();
  .tiles{
    max-width: 500px;
    margin : 0 auto;
    ul{
      padding: 0;
      margin-bottom: $margin-sm;
    }
    .tile{
      width: calc(100%/2);
      padding-top: calc(100%/2);
      &:nth-child(1) div,
      &:nth-child(3) div{
        padding-left: 0;
      }

      &:nth-child(2) div,
      &:nth-child(4) div{
        padding-right: 0;
      }

      a{
        background-color: $secondary-color;
      }
    }
  }

}
