@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';


.support-contact{
  display: flex;
  align-items: flex-start;
  // justify-content: flex-start;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  h3,p{
    margin: 0 5px;
    font-size: $small;
    white-space: nowrap;
    margin-bottom: 0;
    a{
      color: $text-color;
      text-decoration: none;
    }
  }

  p {
    margin-bottom: 0 !important;
  }

  .required{
    width: 100%;
    margin-bottom: $margin-xs;
    span{
      color: $primary-color;
    }
  }

  h3{
    font-family: "Montserrat-Bold"
  }

  img{
    position: relative;
    top:3px;
    margin: 0 5px;
  }

  .btn-blue{
    background-color: $secondary-color;
    border-color: $secondary-color;
    width: 45px;
    min-width: 45px;
    padding: 0;
  }

}