.animations{
  $height-nav : 64px;
  position: relative;
  height: calc(100% - 70px) !important;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (min-width: #{$md-screen}) {
    height: calc(100% - #{$header-height}) !important;
  }

  .title-page{
    padding-top: 0;
    h2{
      margin-top: 0;
    }
  }

  .mobile-navigation{
    margin: 0 auto;
    >p{
      max-width: 600px;
      margin: 0 auto $margin-sm auto;
      text-align: center;
      @include remove-tap-highlight;
    }
    >div{
      background-color: $white;
      padding: $margin-md;
      border-radius: $radius;
      margin: 0 auto $margin-sm auto;
      max-width: 600px;
      text-align: center;
      @include remove-tap-highlight;

      button{
        margin: $margin-sm 0 0 0
      }

      .warn{
        margin-top: $margin-sm;
        text-align: center;
        text-decoration: underline;
        margin-bottom: 0;
      }

      a{
        text-decoration: none;
      }

      p:not(.warn){
        font-family: "MontSerrat-SemiBold";
        color : $secondary-color;
        text-align: center;
        margin: 0;
        .sub{
          font-family: "MontSerrat-Regular";
          font-size: $small;
          display: block;
        }
      }
    }
  }

  .content.large{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: inherit;
    padding: $margin-sm $margin-md;

    @media screen and (min-width: #{$md-screen}) {
      flex-direction: row;
      flex-wrap: wrap;
      padding: $margin-md;
    }

    .fullwidth{
      width: 100%;
      text-align: center;
      margin: $margin-sm 0;
      b{
        font-family: "MontSerrat-SemiBold";
        color : $secondary-color;
      }

    }

  }

  .col{
    display: flex;
    flex-direction: column;
    width: calc(100%);

    h3{
      font-size: 18px;
      width: 100%;
      @media screen and (max-width: #{$md-screen}) {
        // margin-top: 0;
      }
    }

    .required{
      color : $primary-color;
    }

    .btn-back{
      margin: 0;
      min-width: 100px;
      @include remove-tap-highlight;
    }

    >a{
      margin: 0;
      @include remove-tap-highlight;
    }

    @media screen and (min-width: #{$md-screen}) {
      width: calc(50% - #{$margin-xs});
    }

    .container-files{
      margin-top: 0;
      margin-bottom: $margin-sm;
    }

  }

  .form{
    position: relative;
    &:not(.done){
      .confirm-success{
        display: none;
      }
    }

    &.done{
      .container-formulaire{
        animation-iteration-count: 1;
        animation-duration: .8s;
        animation-name: fadeOut;
        animation-fill-mode: forwards;
      }
      .confirm-success{
        animation-iteration-count: 1;
        animation-duration: .8s;
        animation-delay: .3s;
        animation-name: fadeIn;
        animation-fill-mode: forwards;
      }

    }

    &.done.reset{
      .container-formulaire{
        opacity: 0;
        transform: translate(0,-100px) scale(0.7);
        animation-iteration-count: 1;
        animation-duration: .8s;
        animation-delay: .3s;
        animation-name: fadeIn;
      }
      .confirm-success{
        opacity: 1;
        transform: translate(0,0) scale(1);
        animation-iteration-count: 1;
        animation-duration: .8s;
        animation-name: fadeOut;
      }
    }

    .list-files{
      padding: 0;
      margin: 0 0 $margin-sm 0;
      background-color: $light-grey;
      border: solid 1px #BCD3EB;
      border-radius: $radius;
      li{
        display: flex;
        list-style-type: none;
        padding : $margin-xs $margin-sm;
        font-size: $small;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        &:not(:last-child){
          border-bottom: solid 1px #BCD3EB;
        }
        .delete{
          margin-left: $margin-sm;

          img{
            display: block;
          }

          &:not(:hover) img:last-child{
            display: none;
          }

          &:hover img:first-child{
            display: none;
          }

        }
      }
    }

    .upload-doc .filename{
      font-size: $small;
      color:$md-grey;
    }

    .confirm-success{
      position: absolute;
      top:0;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: $white;
      border-radius: $radius;
      opacity: 0;
      transform: translate(0,-100px) scale(0.7);

      &.small {
        h1{
          margin-bottom: 0;
        }
        img{
          max-height: 110px;
        }
        button{
          margin-bottom: 0;
        }
        p{
          margin: 0 $margin-xs $margin-xs $margin-xs;
        }
      }

      h1{
        font-family: "MontSerrat-Regular";
        color:$secondary-color;
        margin-bottom: $margin-sm;
        font-size: $xl;
      }

      p{
        margin: 0 $margin-xs $margin-md $margin-xs;
        text-align: center;
      }

      img{
        margin: $margin-md 0;
        max-height:100%;
        height:auto;
        object-fit: contain;
      }

      // @media screen and (max-height: 350px) {
      //   img{
      //     max-height: 50px !important;
      //   }
      // }

    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translate(0,-100px) scale(0.7);
      }
      50% {
        opacity: 0;
      }

      90% {
        transform: translate(0,0) scale(0.7);
      }

      100% {
        opacity: 1;
        transform: translate(0,0) scale(1);
      }
    }

    @keyframes fadeOut {
      0% {
        transform: translate(0,0) scale(1);
      }
      50% {
        transform: translate(0,0) scale(0.7);
      }

      90% {
        opacity: 0;
      }

      100% {
        opacity: 0;
        transform: translate(0,-100px) scale(0.7);
      }
    }

  }

  .container-trophy{
    background-color: $white;
    padding: $margin-md;
    border-radius: $radius;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: $margin-sm;

    @media screen and (min-width: #{$sm-screen}) {
      flex-direction: row;
      img{margin-bottom: 0 !important}
      p{text-align: left !important}
    }

    img{
      max-width: 110px;
      margin-right: $margin-md;
      margin-bottom: $margin-sm;
    }
    p{
      font-family: "MontSerrat-SemiBold";
      color : $secondary-color;
      text-align: center;
    }


  }

  .container-formulaire{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 100%;
    position: relative;
    background-color: $white;
    padding: $margin-md;
    border-radius: $radius;

    .sub{
      color:$md-grey;
    }

    .separator{
      height: 1px;
      background-color: #D8D8D8;
      margin: $margin-sm 0 $margin-md 0;
    }

    textarea{
      margin: $margin-sm 0;
    }

    button{
      margin-bottom: $margin-sm;
    }

    .container-dimension{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      >div{
        margin: 3px;
      }
      .container-input{
        max-width: 120px;
        label{
          display: none;
        }
      }

      p{
        width: 100%;
        margin-bottom: $margin-xs;
      }

    }

    .overlay-loading{
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      background-color: rgba(240,240,245,0.7);
    }

    .container-button{
      text-align: right;
      button{
        margin-bottom: 0;
      }
    }

  }

}
