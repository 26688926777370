.store-form{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 $margin-sm $margin-sm $margin-sm;
  overflow-y: auto;
  box-sizing: border-box;
  height: calc(100% - 70px) !important;

  @media screen and (min-width: #{$md-screen}) {
    padding: $margin-sm;
    height: calc(100% - #{$header-height}) !important;
  }

  @include sectionTitle();

  .title-page{
    padding-top: 0;
  }

  section{
    background: $white;
    padding: $margin-sm $margin-md;
    margin-bottom: $margin-sm;
    width: 100%;
    border-radius: 0;
    @media screen and (min-width: #{$sm-screen}) {
      border-radius: $radius;
    }
  }

  label{
    .required{
      color: $primary-color;
    }
  }

  .identification{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .container-input{
      width: 100%;

      @media screen and (min-width: #{$sm-screen}) {
        width:49%;
      }

      @media screen and (min-width: #{$md-screen}) {
        width:24%;
      }

    }

    .container-submit{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: 100%;

      .container-feedback{
        text-align: center;
        width: 175px;
      }
    }

    +.container-select-presence{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: $margin-sm $margin-md 0 $margin-md;
      width: 100%;

      .select-items{
        margin: $margin-sm 0 0 0;
      }

      @include loader();
      .loader{
        position: relative;
        top:-5px;
      }

      p{
        margin: 0;
        margin-right: $margin-sm;
        width: 100%;
      }

    }
  }

  @include addContact();
  @include lineUser();

  .line-user{
    flex-wrap:nowrap;
    transition: opacity .4s ease-in-out,transform .4s ease-in-out;
    border-radius: $radius;

    @media screen and (min-width: #{$md-screen}) and (max-width: "1260px") {
      align-items: stretch;
    }

    @media screen and (max-width: #{$sm-screen}) {
      flex-direction: column-reverse;
      padding: 0;
      width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (min-width: #{$sm-screen}) and (max-width: #{$md-screen}) {
      padding: 0;
      >div{
        padding: $margin-sm !important;
      }

      .container{
        background-color: $secondary-color;
        color : white;
        border-radius: $radius 0 0 $radius;
        width: 100%;
        max-width: 33%;
        @media screen and (max-width: #{$sm-screen}) {
          border-radius: 0;
        }
      }

    }

    .name {
      font-family: "Montserrat-SemiBold";
    }

    .edit{
      flex-wrap:nowrap;

      img{
        position: relative;
        top:1px;
      }

      @media screen and (max-width: #{$md-screen}) {
        width: auto;
      }
      @media screen and (max-width: #{$sm-screen}) {
        justify-content: space-between;
        background: $secondary-color;
        margin-bottom: $margin-sm;
        border-radius: $radius $radius 0 0;
        color : white;

        img{
          position: relative;
          top:1px;
        }

        .user{
          display: block;
          width: 100%;
          p{
            &.name {
              margin-bottom: $margin-xs;
            }
            &:not(.name){
              font-size: $small;
            }
            margin: 0;
            color : white;
          }
        }
      }

      .toggle-edit{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        label{
          display: none;
          text-decoration: underline;
          margin: 0 7px 0 0;
          cursor: pointer;
          &:hover{
            color : $secondary-color
          }
          @media screen and (min-width: #{$md-screen}){
            display: inline-block;
          }
        }

        &:not(:hover) img:nth-child(3){
          display: none;
        }

        &:hover img:nth-child(2){
          display: none;
        }

        img {
          position: relative;
          top:1px;
          width: 14px;

        }
      }

    }

    &.fade-out{
      opacity: .4;
      transform: scale(0.9);
    }

    .presence-days{
      width: 100%;

      ul{
        display: flex;
        flex-direction: column;
        flex-wrap:nowrap;
        justify-content: center;
        align-items: flex-start;


        @media screen and (min-width: #{$md-screen}) and (max-width: "1260px") {
          align-items: center;
        }

        @media screen and (min-width: "1260px") {
          flex-direction: row;
        }
        li{
          padding: 4px 8px;
          border-radius: 14px;
          margin: 0 3px;

          &:not(.day){

            @media screen and (min-width: #{$sm-screen}){
              margin-bottom: $margin-xs;
              padding: 0;
            }
            @media screen and (max-width: #{$sm-screen}){
              margin: 0 3px 3px 3px;
            }
          }

          &.day{
            font-size: $small;

            @media screen and (max-width: #{$sm-screen}){
              margin: 0 0 $margin-xs $margin-xs !important;
            }

            @media screen and (min-width: #{$sm-screen}) and (max-width: "1260px") {
              &:not(:last-child){
                margin: 0 3px $margin-xs 3px !important;
              }
            }

            &.disabled{
              border: solid 1px $text-disabled-color;
              opacity: 0.3;
              p,
              p span{
                color: $text-disabled-color;
              }
            }


            &:not(.disabled){
              border: solid 1px $secondary-color;
              background-color: $secondary-color;
              p,
              p span{
                color: white;
              }
            }
          }

          p.cocktail{
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              position: relative;
              top:-2px;
            }
          }
          white-space: nowrap;
        }
      }
    }
  }

  .container-mobile{
    position: fixed;
    top: 0;
    bottom: 0;
    left:0;
    width: 100%;
    z-index: 100;
    overflow-y: auto;
    background-color: rgba(30,47,102,.7);
  }

  .overlay-module-contact{
    position: fixed;
    top: 0;
    bottom: 0;
    left:0;
    width: 100%;
    z-index: 100;
  }

  .module-contact{
    position: absolute;
    top: $margin-md;
    left:50%;
    width: 90%;
    transform: translateX(-50%);
    border-radius: $radius;

    z-index: 101;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: $margin-md;

    @media screen and (min-width: #{$sm-screen}) {
      width: 100%;
      position: relative;

      top: inherit;
      left: inherit;
      transform: inherit;

    }


    section{ width: 100%;}


    .container-input,
    .container-email{
      width: 100%;

      @media screen and (min-width: #{$sm-screen}) {
        width:49%;
      }
      @media screen and (min-width: 1100px) {
        width:24%;
      }

    }

    .container-button{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: -50px;
      margin-left: -25px;
      margin-right: -25px;
      width: calc(100% + 50px);
      @media screen and (min-width: #{$sm-screen}) {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
      button{
        margin: $margin-sm $margin-xs;
        min-width: inherit;

        &.secondary{
          background-color: white;
        }
      }
    }

    .container-email .container-input{
      width: 100%;
    }

    .container-switch{
      width: 100%;
      margin-top: $margin-sm;
      img{
        position: relative;
        top:2px;
        width: 16px;
        margin-right: 5px;
      }
      .required{
        color : $primary-color;
      }
    }

    .container-select-items{
      width: 100%;
      margin-top: $margin-sm;
      @media screen and (min-width: 1100px) {
        margin-top: 0;
        width: calc(74% + 7px);
      }

    }

  }

  .add-contact{
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: $margin-lg;
    p {
      text-decoration: underline;
      margin-bottom: 0;
    }

    .add{
      background-color: $primary-color;
      color: $white;
      height: 30px;
      width: 30px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: $margin-xs;
      font-size: $h2;
      cursor: pointer;
      transition: all .3s ease-in-out;
  
    }

    span{
      color: $primary-color;
    }
  }

  .support-contact{
    box-sizing: border-box;
    max-width: 1124px;
    margin: 0 auto;
    width: 100%;
    padding: $margin-sm;

    @media screen and (min-width: #{$sm-screen}) {
      width: 90%;
      padding: $margin-sm 0;
    }
  }

}
