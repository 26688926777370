.products-page{
  position: relative;
  $height-nav : 64px;
  height: calc(100% - #{$height-nav}) !important;

  @media screen and (min-width: #{$md-screen}) {
    height: calc(100% - 90px) !important;
  }

  >.loader{
    position: fixed;
    top:50%;
    left:50%;
  }

  >div{
    position: absolute;
    width: 100%;
    top:0px;
    bottom: 0;

    // display: flex;
    // justify-content: space-between;
    // flex-direction: column;

    >.content{
      @media screen and (max-width: #{$md-screen}) {
        padding-bottom: $margin-xs;
      }
    }
  }

}

.products{
  height: 100%;
  overflow-y: auto;
  @include inputNumber();

  .hide-on-tablet{
    display: inherit;
    @media screen and (max-width: #{$md-screen}){
      display: none !important;
    }
  }

  .info-products{
    font-family: "Montserrat-SemiBold";
    color : $primary-color;
    text-align: center;
    @media screen and (max-width: #{$md-screen}) {
      padding: 0 $margin-sm;
    }
  }

  .more{
    font-family: "Montserrat-SemiBold";
    margin-bottom : $margin-xs;
    padding: 0 $margin-sm;

    @media screen and (min-width: #{$md-screen}){
      padding: 0;
    }
  }

  p b{
    font-family: "Montserrat-Bold"
  }

  .container-header-list{
    &.fixed{
      top:0;
      left:0;
      position: fixed;
      z-index: 100;
      background-color: $light-grey;
      width: 100%;
      padding: 0 $margin-sm;

      @media screen and (min-width: #{$sm-screen}){
        max-width: 1224px;
        width: 90%;
        left:50%;
        transform: translateX(-50%);
        padding: 0;
      }

      &:after,&:before{
        content : "";
        position: absolute;
        top:0;
        display: block;
        height: 100%;
        width: 200px;
        background-color: $light-grey;
      }
      &:after{
        right: 0;
        transform: translateX(100%);
      }
      &:before{
        left: 0;
        transform: translateX(-100%);
      }
    }
  }

  .filters{
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0;
    margin-bottom: 20px;

    @media screen and (max-width: #{$sm-screen}){
      margin-bottom: $margin-md;
    }


    @media screen and (min-width: #{$md-screen}){
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0px;
    }

    .css-2b097c-container:last-child{
      margin-right: 0;
      @media screen and (max-width: 650px){
        margin-top: $margin-sm;
      }
    }



    .css-2b097c-container,.field-search{
      min-width: 100%;
      max-width: 100%;
      margin-right: $margin-sm;
      @media screen and (min-width: 650px){
        min-width: 300px;
        max-width: calc(100% - 330px);
      }
    }

    .field-search{
      position: relative;
      display: inline-block;
      max-width: 614px !important;
      width:614px;
      @media screen and (min-width: 1060px){
        max-width: 300px;
        width:auto;
      }
      input{
        padding-right: 35px;
      }

      img{
        position: absolute;
        top:50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }
  }

  .container-header-list.fixed+ul{

    // $xs-screen : 360px;
    // $sm-screen : 767px;
    // $md-screen : 1224px;
    // $md-nav-screen : 1144px;
    padding-top: 195px;


    @media screen and (min-width: '650px'){
      padding-top: 135px;
    }

    @media screen and (min-width: '1060px'){
      padding-top: 95px;
    }


  }

  @include listProducts();
  .list-products{
    padding-bottom: $margin-md !important;

    ul li a.prev-selected{
      animation: highlight 1s;
      animation-delay: 1s;
      animation-iteration-count: 1;
      @keyframes highlight {
        0% {
          @include shadow();
          background-color: white;
        }
      
        50% {
          background-color: red;
          background-color: rgba(0, 95, 191, .1);
        }

        100% {
          background-color: white;
        }

      }
    }

    .scroll-loader{
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: $margin-md 0;
    }

    .indicator-end{
      position: relative;
      height: 60px;
      @media screen and (min-width : $sm-screen) {
        height: 200px;
      }
      &:after{
        position: absolute;
        content: "";
        display: block;
        top:50%;
        left:50%;
        height: 2px;
        width: calc(100% - 60px);
        transform: translateX(-50%);
        max-width: 1000px;
        background-color: $secondary-color;
      }
    }

    >button:first-child{
      margin-top: $margin-xs;
      .loader{
        background-color: white;
      }
    }

    button.goto-order{
      margin-bottom: 0;
      color: $primary-color;
      border-color: $primary-color;
    }

    .mobile-segment-ref{
      p{ font-size: $regular}
      p:first-child{
        color : $secondary-color;
        font-size: 16px;
        font-family: "Montserrat-SemiBold";
        margin-bottom: 3px;
      }
      p:nth-child(2){
        color : $text-color;
        font-family: "Montserrat-SemiBold"
      }
      p:nth-child(3){
        color : $md-grey;
        font-family: "Montserrat-Regular"
      }
    }

    .product-segment{
      max-width: 140px;
    }

    .product-ref .hide-on-tablet{
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .container-thumbnail-tablet{
      width: 100%;
      font-size: 0;
      .thumbnail-tablet{
        position: relative;
        background-color: white;
        margin-right: 10px;
        // background-color: $light-grey;
        max-width: 300px;
        height: 300px;
        margin: 0 auto $margin-md auto;
  
        &.error{
          display: none;
        }
      
        .container-loader-img{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
  
        img{
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }


    .thumbnail{
      position: relative;
      min-width:45px;
      height: 45px;
      overflow: hidden;
      background-color: white;
      margin-right: 10px;
      background-color: $light-grey;
      border-radius: 50%;

      &.error{
        display: none;
      }
    
      .container-loader-img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        svg{
          transform: scale(.5);
        }
      }
    
      img{
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }


    @media screen and (max-width: #{$md-screen}){
      .product-ref,
      .product-price{
        width: 50%;
        margin-bottom: $margin-sm;
      }

      .product-price{
        text-align: right;
      }

      .product-qty{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .product-qty .only-on-tablet{
        display: block;
      }

    }


    @media screen and (min-width: #{$md-screen}){

      >ul li{
        .product-packing p{
          text-align: center;
          width: 62px;
        }
      }

      .product-qty .product-packing,
      .product-qty .product-segment{
        display: none;
      }


    }

  }


}
