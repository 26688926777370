.step1{
  section{
    margin: 0 $margin-md $margin-sm $margin-md;
    border-radius: $radius;

    @media screen and (min-width: #{$sm-screen}) {
      margin: $margin-sm;
    }
  }

  .dimension {
    .section-title{
      margin-bottom: 0;
      padding-bottom: 0;
    }
    section {
      padding: $margin-md;
    }
  }

  .list-options{

    h2{
      padding: $margin-md;
      line-height: 30px;
      @media screen and (min-width: #{$sm-screen}) {
        padding: $margin-md $margin-sm $margin-sm $margin-sm;
      }
    }

    .container-switch{
      .switch-field{
        margin: $margin-md 0;
      }
    }

    .standiste{
      padding: $margin-md 0;

      .section-title{
        padding: 0;
      }

      .container-inputs{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .container-input{
          width: 100%;
        }
        @media screen and (min-width: #{$sm-screen}) {
          flex-direction: row;
          .container-input{
            width: calc(33% - 5px);
          }
        }
      }

    }
  }

}
