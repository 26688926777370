.badges{
  $height-nav : 64px;
  height: calc(100% - #{$height-nav}) !important;
  overflow-y: auto;
  // for app
  // overflow-y: scroll;

  @media screen and (min-width: #{$md-screen}) {
    height: calc(100% - 90px) !important;
  }

  button{
    margin-bottom: 0;
  }

  .hide-on-tablet {
    display: inline-block;
  }

  .title-page{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $margin-sm;
    padding-top: $margin-sm;
    @media screen and (max-width: #{$md-screen}) {
      display: block !important;
      padding-top: 0;
      h2{
        font-family: "Montserrat-Medium";
        font-size: $medium;
      }
    }

    p{
      margin-top: 5px;
    }

  }

  .content{
    padding: 0 $margin-sm;
    >button{
      margin-top: $margin-md;
    }
  }

  .list-badges{
    margin-top: $margin-sm;
    .line{
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $white;
      margin-bottom: $margin-sm;
      padding: $margin-xs;
      border-radius: $radius;
      @include shadow;

      p{
        margin: 0;
      }

      .info-user{
        display: flex;
        align-items: center;
        justify-content: space-between;
        >div{
          padding: $margin-xs;
        }
      }

      img{
        margin-right: $margin-xs;
      }

      .name{
        position: relative;
        min-width: inherit;
        height: 100%;
        @media screen and (min-width: #{$md-screen}) {
          border-right: solid 1px $md-grey;
        }
      }

    }
  }

}
