.subscribe{
  position: absolute;
  top:70px;
  height: calc(100% - 70px) !important;
  width: 100%;

  @media screen and (min-width: #{$md-screen}) {
    top:$header-height;
    height: calc(100% - #{$header-height}) !important;
  }

  .section-title{
    font-family: "Montserrat-SemiBold";
    padding: $margin-sm $margin-md;
    @include remove-highlight();
    @include remove-tap-highlight();
    .required{
      color : $primary-color;
    }
  }


  section{
    background: $white;
    padding: $margin-sm $margin-md;
    margin-bottom: $margin-sm;
    max-width: 1024px;
    border-radius: 0;
    box-shadow: $shadow;

    @media screen and (min-width: #{$sm-screen}) {
      border-radius: $radius;
      padding: $margin-md $margin-md;
    }
  }

  section button.secondary{
    margin-top: $margin-xs;
  }

  label{
    .required{
      color: $primary-color;
    }
  }

}

.subscribe .formulaire{

  .main-user{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .container-select{
      max-width: 100%;
      width: 100%;
      margin-bottom: $margin-xs;
      @media screen and (min-width: #{$sm-screen}) {
        max-width: 49%;
        margin-bottom: 0; 
      }

      @media screen and (min-width: #{$md-screen}) {
        max-width: 32%;
      }

      label{
        display: block;
        margin-bottom: $margin-xs;
      }
    }

    // >span{
    //   width: 100%;
    //   min-width: 100%;
    //   display: flex;
    //   flex-wrap: wrap;
    //   justify-content: space-between;
    //   padding: $margin-sm $margin-md;
    //   transition: all .5s ease-in-out;
    //   @media screen and (min-width: #{$sm-screen}) {
    //     padding: $margin-md $margin-md;
    //   }
    //
    //   &:first-child{
    //     transform: translateX(-100%);
    //   }
    //   &:last-child{
    //     transform: translateX(-100%);
    //   }
    //
    //   &.container-select-main-user{
    //     align-items: center;
    //     justify-content: center;
    //
    //     .container-select{
    //       max-width: 300px;
    //       width: 100%;
    //     }
    //
    //   }
    //
    // }

    .container-input{
      width: 100%;

      @media screen and (min-width: #{$sm-screen}) {
        width:49%;
      }

      @media screen and (min-width: #{$md-screen}) {
        width:32%;
      }

    }
  }

  >.warn,>.succeed{
    padding: $margin-sm $margin-md 0 $margin-md;
    margin: 0;
    @media screen and (min-width: #{$md-screen}) {
      padding: 0;
      text-align: right;
    }
  }

  .company{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .container-input{
      width: 100%;
      margin-right: 0;
      @media screen and (min-width: #{$md-screen}) {
        width:31%;
        margin-right: 2%;
      }

    }
  }


  .module-contacts{

    ul{
      padding: 0;
      margin: 0;
      li{
        list-style-type: none;
        display: block;
        padding: $margin-sm 0;
        .header-contact{
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0 $margin-xs;
          p{
            color : $md-grey;
            margin: 0;
            white-space: nowrap;
          }
          .line{
            height: 1px;
            width: 100%;
            background-color: $md-grey;
            margin: 0 $margin-sm;
          }
          .remove{
            background-color: transparent;
            border: none;
            margin: 0;
            width: auto;
            min-width: auto;
            padding: 0;
            height: 30px;

            img:first-child{
              display: inline;
            }

            img:last-child{
              display: none;
            }


            &:hover{
              img:first-child{
                display: none;
              }
              img:last-child{
                display: inline;
              }
            }

          }
        }
      }

      .contact{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      .contact .container-input{
        padding: $margin-xs;
        width: 100%;

        @media screen and (min-width: #{$sm-screen}) {
          width:50%;
        }

        @media screen and (min-width: #{$md-screen}) {
          width:33.33%;
        }

      }

    }

    //select functions
    .css-2b097c-container{
      margin-bottom: $margin-xs;
    }

    .container-add{
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-bottom: $margin-sm;
      &:hover{
        .add {
          background-color: darken($primary-color,3%);
        }
      }
    }

    .container-add .add {
      background-color: $primary-color;
      color: $white;
      height: 30px;
      width: 30px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: $margin-xs;
      font-size: $h2;
      cursor: pointer;
      transition: all .3s ease-in-out;

    }


    .container-add .add-contact{
      cursor: pointer;
      margin-bottom: 0;
      text-decoration: underline;

      span{
        color: $primary-color;
      }
    }


  }

}
