.products-provider {
  overflow-y: auto;
  $height-nav: 64px;
  height: calc(100% - #{$height-nav}) !important;

  @media screen and (min-width: #{$md-screen}) {
    height: calc(100% - 90px) !important;
  }

  .title-page {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    // @media screen and (max-width: #{$md-screen}) {
    //   h2{ display: none !important; }
    // }
    @media screen and (max-width: #{$sm-screen}) {
      padding: 0 $margin-sm;
    }
    button {
      margin-bottom: 0;
      @media screen and (max-width: #{$md-screen}) {
        margin-left: $margin-xs;
      }
    }
  }

  @include listProducts();

  .container-ca {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: $margin-sm;

    @media screen and (min-width: 530px) {
      flex-direction: row;
      justify-content: space-between;
    }

    .title-ca {
      color: $secondary-color;
      h2 {
        margin-top: 0;
        margin-bottom: $margin-xs;
      }
      p {
        margin-bottom: 0;
      }
    }

    button {
      margin-bottom: 0;
    }

    @media screen and (max-width: #{$sm-screen}) {
      padding: 0 $margin-sm;
    }
  }

  .list-products {
    .product-ref-brand {
      display: block;
      width: 50%;
      p {
        font-size: $regular;
      }
      p:not(:last-child) {
        color: $text-color;
        font-family: "Montserrat-SemiBold";
      }
      p:last-child {
        color: $md-grey;
        font-family: "Montserrat-Regular";
      }
    }

    .product-packing-qty {
      display: block;
      text-align: right;
      width: 50%;
      p:last-child {
        color: $secondary-color;
        font-family: "Montserrat-SemiBold";
      }
    }

    .product-price {
      min-width: 120px !important;
      width: 120px !important;
    }
    // ul .product-qty,
    // ul .product-brand,
    // ul .product-packing,
    // .sortBy > div.product-packing,
    // .sortBy > div:last-child {
    //   max-width : inherit !important;
    // }
    //
    // ul .product-qty{
    //   justify-content: flex-start !important;
    // }
  }
}
