@import './select-quizz.scss';
@import './done-quizz.scss';

.container-quizz-switch{
  $height-nav : 64px;
  position: relative;
  height: calc(100% - 70px) !important;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  .select-quizz,
  .question,
  .done-quizz{

    max-width: 425px;
    border-radius: $radius;
    background: $white;
    padding: $margin-sm $margin-md;
    box-sizing: border-box;
    @include shadow();
  }

  .question{
    top:0;
    bottom: 0;
    @include Question()

    h3{
      font-family: "MontSerrat-SemiBold";
      text-transform: uppercase;
      text-align: center;
      margin-bottom: $margin-md;
      span{
      }
    }

    h4{
      font-family: "MontSerrat-SemiBold";
      text-transform: uppercase;
      text-align: center;
      margin-bottom: $margin-md;
      margin-top:-20px;
      color : $secondary-color;
    }

    >ul>li{
      border-radius: 20px;
      border: solid 2px $secondary-color;
      margin: $margin-sm 0;
      padding: $margin-sm;
      transition: all .4s ease-in-out;
      .answer{
        background: transparent;
      }
      &.blue{
        background-color: $secondary-color;
        color: white;
      }
      &.wrong{
        border-color: #B12B29;
      }
      &.correct{
        border-color: #75D850;
      }
      &.disabled{
        border-color: $md-grey;
        p{ color : $md-grey }
      }
    }

    .answer{
      background-color: white;
      text-align: center;
      cursor: pointer;
      p{
        font-family: "MontSerrat-SemiBold";
        margin: 0;
      }
    }

    .container-input{
      display: flex;
      justify-content: space-between;
      align-items: center;
      input{
        flex: 1;
        margin-right: $margin-xs;
      }
      >p { font-family: "MontSerrat-SemiBold"; }
    }

    .container-button{
      margin-top: $margin-lg;
    }

  }

  @include sliderPages()
}
