.avis{
  $height-nav : 64px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: calc(100% - 70px) !important;
  box-sizing: border-box;

  @media screen and (min-width: #{$md-screen}) {
    height: calc(100% - #{$header-height}) !important;
  }

  .avis-inactif{
    margin-top: $margin-md;
  }

  .avis-inactif,
  .first-step,
  .last-step,
  .question,
  .suggestion,
  .form{
    top:0;
    bottom: 0;
    max-width: 425px;
    border-radius: $radius;
    background: $white;
    padding: $margin-md;
    box-sizing: border-box;
    @include shadow();
  }

  .avis-inactif,
  .last-step,
  .first-step{
    h1{
      position: relative;
      color: $text-color;
      text-align: center;
      padding-bottom: $margin-sm;
      margin-bottom: $margin-md;
      &:after{
        content : "";
        position: absolute;
        height: 2px;
        width: 50%; max-width: 100px;
        bottom: 0;
        left:50%;
        transform: translateX(-50%);
        background-color: $primary-color;
      }
    }

    button{
      margin-top: $margin-md;
    }



  }

  .last-step,
  .first-step,
  .suggestion,
  .form{
    .centered{
      flex-direction: column;
    }
  }

  .question,
  .suggestion,
  .form{

    @include Question()
  }

  @include sliderPages()

}
