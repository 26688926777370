@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';

.slider{
  position: relative;
  margin: $margin-xs 0;
  height: 120px;
  width: 100%;
  .slider-nav{
    position: absolute;
    bottom: 0;
    left:50%;
    transform: translateX(-50%);
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;
    li{
      display: inline-block !important;
      width: 14px;
      height: 14px;
      border-radius: 7px;
      background-color: $md-grey;
      margin: 0 $margin-xs;
      &.actived{
        background-color: $primary-color;
      }
      @include shadow;
    }
  }

  >div{
    position: absolute;
    width: 100%;
    height: 90px;
  }

  .slider-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    height: 90px !important;
    width: 100%;
    border-radius: $radius;
    @include shadow();

    &.quizz a,
    &.trophy a{
      display: flex;
      align-items: center;
      padding: $margin-sm;
      img{
        height: 60px;
        margin-right: $margin-sm;
      }
      p{
        text-align: left;
        margin: 0;
      }
    }

    >a{
      width: 100%;
      height: 90px;
      margin-bottom: 0;
      text-decoration: none;
    }

    >p{
      width: 100%;
      margin: 0;
      padding: $margin-sm;
      text-align: center;
    }

    .item-offer{
      display: flex;
      width: 100%;
      height: 90px;
      justify-content: space-between;
      align-items: center;
      padding: $margin-xs $margin-sm;

      .flash{
        position: absolute;
        z-index: 1000;
        top:0;
        left:0;
        transform: translate(-50%,-30%);
        width: 40px;
        height: 40px;

      }

      p{
        margin: 0;
      }

      >div:first-child{
        p:not(.sub){
          font-family: "Montserrat-SemiBold";
          color: $text-color;
        }
        .sub{
          color : $text-disabled-color;
        }
      }

      .price p{
        font-family: "Montserrat-SemiBold";
        font-size: $h2;
      }
    }

  }

}